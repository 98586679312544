<template>
  <el-main id="main">
    <el-row justify="center">
      <el-col :sm="{span: 24}" :md="{span: 8}" class="adv">
        <el-col class="adv-line">
          <h1>20年</h1>
          <h1>行业经验</h1>
        </el-col>
      </el-col>
      <el-col :sm="{span: 24}" :md="{span: 8}" class="adv">
        <el-col class="adv-line">
          <h1>业务覆盖全省</h1>
          <h1>及部分周边省区</h1>
        </el-col>
      </el-col>
      <el-col :sm="{span: 24}" :md="{span: 8}" class="adv">
        <el-col class="adv-line">
          <h1>多家企事业单位</h1>
          <h1>选择和信赖</h1>
        </el-col>
      </el-col>
    </el-row>

    <divider-line white="我们的" red="服务" grey="OUR SERVICES"></divider-line>

    <el-row class="icon">
      <el-row type="flex" justify="center">
        <el-col>
          <el-link href="#/product/225">
            <el-image :src="require('@/assets/2019052708政府单位宣传片.jpg')"></el-image>
            <h4>政府/事业单位宣传片</h4>
          </el-link>
        </el-col>
        <el-col>
          <el-link href="#/product/224">
            <el-image :src="require('@/assets/2019052736汇报片.jpg')"></el-image>
            <h4>专题片/汇报片</h4>
          </el-link>
        </el-col>
        <el-col>
          <el-link href="#/product/223">
            <el-image :src="require('@/assets/2019052746党建片.jpg')"></el-image>
            <h4>党建片</h4>
          </el-link>
        </el-col>
        <el-col>
          <el-link href="#/product/209">
            <el-image :src="require('@/assets/2019052706产业宣传片、宣传片.jpg')"></el-image>
            <h4>产品广告片/公益广告片</h4>
          </el-link>
        </el-col>
        <el-col>
          <el-link href="#/product/208">
            <el-image :src="require('@/assets/2019052724微电影.jpg')"></el-image>
            <h4>定制微电影</h4>
          </el-link>
        </el-col>
      </el-row>
      <el-row type="flex" justify="center">
        <el-col>
          <el-link href="#/product/207">
            <el-image :src="require('@/assets/2019052732MG动画.jpg')"></el-image>
            <h4>MG动画</h4>
          </el-link>
        </el-col>
        <el-col>
          <el-link href="#/product/206">
            <el-image :src="require('@/assets/2019052748MV.jpg')"></el-image>
            <h4>音乐/MV</h4>
          </el-link>
        </el-col>
        <el-col>
          <el-link href="#/product/205">
            <el-image :src="require('@/assets/2019052711微信图片_20190527105626_r17_c3.jpg')"></el-image>
            <h4>晚会/演出/活动高清多机位录制</h4>
          </el-link>
        </el-col>
        <el-col>
          <el-link href="#/product/204">
            <el-image :src="require('@/assets/2019052725网络直播.jpg')"></el-image>
            <h4>网络直播</h4>
          </el-link>
        </el-col>
        <el-col>
          <el-link href="#/product/203">
            <el-image :src="require('@/assets/2019052738比赛现场评分系统.jpg')"></el-image>
            <h4>比赛现场评分系统</h4>
          </el-link>
        </el-col>
      </el-row>
    </el-row>

    <divider-line white="近期" red="作品" grey="LATEST PRODUCTS"></divider-line>

    <latest-bar></latest-bar>

    <divider-line white="案例" red="展示" grey="CASES SHOW"></divider-line>

    <el-row class="list-bar">
      <case-bar :title="products[225].title" :list="products[225].list.slice(0, 4)" :col="4" src="#/product/225"></case-bar>
      <case-bar :md="{span: 12}" :title="products[224].title" :list="products[224].list.slice(0, 2)" :col="2" src="#/product/224"></case-bar>
      <case-bar :md="{span: 12}" :title="products[223].title" :list="products[223].list.slice(0, 2)" :col="2" src="#/product/223"></case-bar>
      <case-bar :md="{span: 12}" :title="products[209].title" :list="products[209].list.slice(0, 2)" :col="2" src="#/product/209"></case-bar>
      <case-bar :md="{span: 12}" :title="products[208].title" :list="products[208].list.slice(0, 2)" :col="2" src="#/product/208"></case-bar>
    </el-row>

    <el-image class="image" :src="require('@/assets/ad1.png')"></el-image>

    <el-container>
      <el-row class="list-bar">
        <case-bar :md="{span: 12}" :title="products[207].title" :list="products[207].list.slice(0, 2)" :col="2" src="#/product/207"></case-bar>
        <case-bar :md="{span: 12}" :title="products[206].title" :list="products[206].list.slice(0, 2)" :col="2" src="#/product/206"></case-bar>
        <case-bar :md="{span: 12}" :title="products[205].title" :list="products[205].list.slice(0, 2)" :col="2" src="#/product/205"></case-bar>
        <case-bar :md="{span: 12}" :title="products[204].title" :list="products[204].list.slice(0, 2)" :col="2" src="#/product/204"></case-bar>
        <case-bar :md="{span: 12}" :title="products[203].title" :list="products[203].list.slice(0, 2)" :col="2" src="#/product/203"></case-bar>
      </el-row>
    </el-container>

    <el-image class="image" :src="require('@/assets/ad2.png')"></el-image>
  </el-main>
</template>

<script>
import DividerLine from "@/components/DividerLine";
import CaseBar from "@/components/CaseBar";
import products from "@/mocks/products.json";
import LatestBar from "@/components/LatestBar";

export default {
  name: "home",
  components: {
    LatestBar,
    CaseBar,
    DividerLine
  },
  data() {
    return {
      carousel: [
        require('@/assets/carousel-1.png'),
        require('@/assets/carousel-2.png')
      ],
      products: products
    }
  }
}
</script>

<style scoped>
#main {
  overflow: unset;
}

.adv {
  padding: 10px;
  margin: 12px 0 48px;
  color: #FFFFFF;
  text-align: center;
  font-size: 1.25rem;
  letter-spacing: 0.1rem;
  line-height: 2rem;
}

.adv:nth-child(1) {
  background-color: #fd992f;
}

.adv:nth-child(2) {
  background-color: #2d319e;
}

.adv:nth-child(3) {
  background-color: #088b49;
}

.adv-line {
  border: #FFF solid 1px;
}

.icon {
  margin: 128px 0 64px;
}

.latest > h3 {

}

.list-bar {
  margin: 64px 0;
  padding: 0 -2px;
}

.image {
  display: block;
  left: -17vw;
  width: 98vw;
}

@media screen and (max-width: 1200px) {
  .image {
    left: -9.25vw;
    width: 96vw;
  }
}

@media screen and (max-width: 992px) {
  .image {
    left: -9.25vw;
    width: 94vw;
  }
}

@media screen and (max-width: 768px) {
  .image {
    left: -2vw;
    width: 95vw;
  }
}
</style>
