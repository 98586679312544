<template>
  <el-row>
    <el-col>
      <list-bar title="最新资讯" :items="news"></list-bar>
    </el-col>
    <el-col>
      <list-bar title="联系我们" :items="contact"></list-bar>
    </el-col>
  </el-row>
</template>

<script>
import ListBar from "@/components/ListBar";

export default {
  name: "side-bar",
  components: {ListBar},
  data() {
    return {
      news: [
        {
          title: "活动会议摄像师需要知道些",
          src: "#"
        },
        {
          title: "宣传片拍摄省预算需要注意",
          src: "#"
        },
        {
          title: "课程视频课件视频教学视频",
          src: "#"
        },
        {
          title: "会议摄像公司应该怎样选择",
          src: "#"
        },
        {
          title: "影视制作的器材主要都有哪",
          src: "#"
        },
      ],
      contact: [
        {
          title: "电话：18183520529",
          src: "#"
        },
        {
          title: "手机：18183520529",
          src: "#"
        },
        {
          title: "E-mail：65990643@qq.com",
          src: "#"
        },
        {
          title: "联系人： 胡老师",
          src: "#"
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>