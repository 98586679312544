<template>
  <el-row class="carousel">
    <el-carousel height="31.25vw" :interval="5000" arrow="always">
      <el-carousel-item v-for="item of carousel" :key="item">
        <el-image style="width: 100%; height: 100%" :src="item" fit="scale-down"></el-image>
      </el-carousel-item>
    </el-carousel>
  </el-row>
</template>

<script>
export default {
  name: "carousel-bar",
  data() {
    return {
      carousel: [
        require('@/assets/carousel-1.png'),
        require('@/assets/carousel-2.png')
      ],
    }
  }
}
</script>

<style scoped>
.carousel {
  margin: 60px 0 0;
}
</style>