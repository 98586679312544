<template>
  <el-row type="flex" justify="center">
    <el-col :span="24">

      <el-row type="flex" justify="center">

        <el-col class="side hidden-sm-and-down" :md="6" :lg="6" type="flex" justify="center">
          <side-bar></side-bar>
        </el-col>

        <el-col :xs="18" :md="18">
          <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>公司动态</el-breadcrumb-item>
          </el-breadcrumb>
          <el-row type="flex" justify="start">
            <el-col class="content">
              <el-empty description="暂无内容" :image-size="200"></el-empty>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
import SideBar from "@/components/SideBar";
export default {
  name: "news",
  components: {SideBar}
}
</script>

<style scoped>
.side {
  margin: 64px 24px;
}

.breadcrumb {
  padding: 24px;
}

.content {
  padding: 24px;
  margin-bottom: 128px;
  text-align: justify;
  text-indent: 2.3rem;
  color: rgb(63, 63, 63);
  font-family: Verdana, Arial, Helvetica, sans-serif;
}
</style>