<template>
  <el-col :span="span" :xs="xs" :sm="sm" :md="md" :lg="lg" :col="col" class="case-bar">
    <el-row type="flex" justify="space-between">
      <el-row type="flex" justify="space-between">
        <el-image :src="require('@/assets/title.png')" class="title__img"></el-image>
        <h3><slot>{{ title }}</slot></h3>
      </el-row>
      <span class="title__link"><el-link :href="src">更多视频+</el-link></span>
    </el-row>
    <el-row>
      <el-col :span="s" v-for="(item, key) in list" :key="key">
        <a :href="item.src" target="_blank">
          <el-image class="list-bar__img" :src="require('@/assets/images/' + item.img)" lazy></el-image>
          <p class="list-bar__title">{{item.title}}</p>
        </a>
      </el-col>
    </el-row>
  </el-col>
</template>

<script>
export default {
  name: "case-bar",
  props: [
      "xs",
      "sm",
      "md",
      "lg",
      "span",
      "col",
      "title",
      "list",
      "src"
  ],
  data() {
    return {
      s: 24 / (this.col ?? 4)
    }
  }
}
</script>

<style scoped>
a:visited {
  color: #2c3e50;
  text-decoration: none;
}
a:hover {
  color: #2c3e50;
  text-decoration: none;
}
a:link {
  color: #2c3e50;
  text-decoration: none;
}
a:active {
  color: #2c3e50;
  text-decoration: none;
}

.title__img {
  margin: 0 0 8px;
}

.list-bar__img {
  width: 96%;
  aspect-ratio: 16 / 9;
}

.list-bar__title {
  font-size: 1rem;
  line-height: 1rem;
  margin-bottom: 36px;
  padding: 0;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title__link {
  display: flex;
  align-items: center;
  margin-right: 1vw;
}
</style>
