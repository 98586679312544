<template>
      <el-col>
        <el-row type="flex" justify="start">
          <el-image :src="require('@/assets/title.png')" class="title__img"></el-image>
          <h2>{{ title }}</h2>
        </el-row>
        <el-menu class="menu__side" mclass="el-menu-vertical-demo" router>
          <el-menu-item :index="key.toString()" v-for="(item, key) in items" :key="key" :route="item.src">
            {{ item.title }}
          </el-menu-item>
        </el-menu>
      </el-col>
</template>

<script>
export default {
  name: "list-bar",
  props: [
      "title",
      "items"
  ]
}
</script>

<style scoped>
.title__img {
  margin: 4px 8px;
}

.menu__side {
  display: block;
}
</style>