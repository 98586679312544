<template>
  <el-row class="header" type="flex" justify="center">
    <el-col :lg="5" class="hidden-md-and-down">
      <el-link href="/" :underline="false">
        <el-image :src="require('@/assets/logo.png')"></el-image>
      </el-link>
    </el-col>
    <el-col :xs="24" :md="20" :lg="13" class="menu">
      <el-menu background-color="#F7F7F7" text-color="#666666" active-text-color="#FF0000" :default-active="activeIndex" mode="horizontal" router>
        <el-menu-item index="1" class="menu-line" route="/">网站首页</el-menu-item>
        <el-menu-item index="2" class="menu-line" route="/about">关于我们</el-menu-item>
        <el-submenu index="3">
          <template slot="title">作品案例</template>
          <el-menu-item index="3-1" route="/product/225">政府/企事业单位宣传片</el-menu-item>
          <el-menu-item index="3-2" route="/product/224">专题片/汇报片</el-menu-item>
          <el-menu-item index="3-3" route="/product/223">党建片</el-menu-item>
          <el-menu-item index="3-4" route="/product/209">产品广告片/公益广告片</el-menu-item>
          <el-menu-item index="3-5" route="/product/208">定制微电影</el-menu-item>
          <el-menu-item index="3-6" route="/product/207">MG动画</el-menu-item>
          <el-menu-item index="3-7" route="/product/206">音乐/MV</el-menu-item>
          <el-menu-item index="3-8" route="/product/205">晚会/演出/活动高清多机位录制</el-menu-item>
          <el-menu-item index="3-9" route="/product/204">网络直播</el-menu-item>
          <el-menu-item index="3-10" route="/product/203">比赛现场评分系统</el-menu-item>
        </el-submenu>
        <el-menu-item index="4" class="menu-line menu-line-left" route="/service">我们的服务</el-menu-item>
        <el-submenu index="5" route="/news">
          <template slot="title">影禾动态</template>
          <el-menu-item index="5-1" route="/news/1">公司动态</el-menu-item>
          <el-menu-item index="5-2" route="/news/2">行业资讯</el-menu-item>
          <el-menu-item index="5-3" route="/news/3">拍摄花絮</el-menu-item>
        </el-submenu>
        <el-menu-item index="6" class="menu-line-left" route="/contact">联系我们</el-menu-item>
      </el-menu>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "header-bar",
  props: [
    "activeIndex"
  ]
}
</script>

<style scoped>
.header {
  background-color: #F7F7F7;
  align-items: center;
}

.menu {
  display: flex;
  justify-content: center;
}

.menu-line::after {
  position: absolute;
  color: #666666;
  top: 50%;
  right: 0;
  height: 1.4rem;
  border-left: 1px solid;
  content: "";
  transform: translateY(-50%);
}

.menu-line-left::after {
  position: absolute;
  color: #666666;
  top: 50%;
  left: 0;
  height: 1.4rem;
  border-right: 1px solid;
  content: "";
  transform: translateY(-50%);
}
</style>