import Vue from 'vue'
import Router from 'vue-router'
import home from "@/pages/home";
import about from "@/pages/about";
import contact from "@/pages/contact";
import service from "@/pages/service";
import product from "@/pages/product";
import news from "@/pages/news";


Vue.use(Router)

export default new Router({
    routes: [
        {
            path: "/",
            name: "index",
            component: home
        },
        {
            path: "/home",
            name: "home",
            component: home
        },
        {
            path: "/about",
            name: "about",
            component: about
        },
        {
            path: "/product",
            name: "product",
            component: product,
            children: [
                {
                    path: "225",
                    name: "225",
                    component: product
                },
                {
                    path: "224",
                    name: "224",
                    component: product
                },
                {
                    path: "223",
                    name: "223",
                    component: product
                },
                {
                    path: "209",
                    name: "209",
                    component: product
                },
                {
                    path: "208",
                    name: "208",
                    component: product
                },
                {
                    path: "207",
                    name: "207",
                    component: product
                },
                {
                    path: "206",
                    name: "206",
                    component: product
                },
                {
                    path: "205",
                    name: "205",
                    component: product
                },
                {
                    path: "204",
                    name: "204",
                    component: product
                },
                {
                    path: "203",
                    name: "203",
                    component: product
                }
            ]
        },
        {
            path: "/service",
            name: "service",
            component: service
        },
        {
            path: "/news",
            name: "news",
            component: news,
            children: [
                {
                    path: "1",
                    name: "1",
                    component: news
                },
                {
                    path: "2",
                    name: "2",
                    component: news
                },
                {
                    path: "3",
                    name: "3",
                    component: news
                }
            ]
        },
        {
            path: "/contact",
            name: "contact",
            component: contact
        }
    ]
});
