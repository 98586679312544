<template>
  <el-container id="app">

    <el-row type="flex" justify="center" class="hidden-lg-and-up">
      <el-col :xs="24" :md="6">
        <el-link href="/" :underline="false">
          <el-image :src="require('@/assets/logo.png')"></el-image>
        </el-link>
      </el-col>
    </el-row>

    <el-header>
      <header-bar :active-index="activeIndex"></header-bar>
    </el-header>

    <carousel-bar></carousel-bar>

    <el-row type="flex" justify="center">
      <el-col :xs="24" :sm="20" :md="20" :lg="16">
        <router-view></router-view>
      </el-col>
    </el-row>

    <el-footer height="200px">
      <footer-bar :active-index="activeIndex"></footer-bar>
    </el-footer>

  </el-container>
</template>

<script>
import HeaderBar from "@/components/HeaderBar";
import CarouselBar from "@/components/CarouselBar";
import FooterBar from "@/components/FooterBar";

export default {
  name: 'App',
  components: {
    FooterBar,
    CarouselBar,
    HeaderBar
  },
  data() {
    return {
      activeIndex: "1",
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body {
  background: #F7F7F7;
  margin: 0;
  padding: 0;
}

.copyright>p {
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar
{
  width: 16px;
  height: 16px;
  background-color: #F5F5F5;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #2d319e;
}
</style>
