<template>
  <el-row>
    <el-col class="product" :span="12" v-for="(item, key) in products" :key="key">
      <el-col>
        <el-link :href="item.src" target="_blank">
          <el-image class="product-bar__img" :src="require('@/assets/images/' + item.img)" lazy></el-image>
          <el-col class="product-bar__title">
            <h3>{{ item.title }}</h3>
          </el-col>
        </el-link>
      </el-col>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "product-bar",
  props: [
      "title",
      "products"
  ]
}
</script>

<style scoped>
.product {
  margin: 20px 0;
}

.product-bar__img {
  width: 22vw;
  height: 12vw;
}

.product-bar__title {
  margin: -8px 0 24px;
  padding: 0 0;
  height: 20px;
}
</style>