<template>
  <el-col>
    <el-row class="last" type="flex" justify="center">
      <el-col :xs="24" :sm="24" :md="20" :lg="16">
        <case-bar title="近期作品" :list="latest.slice(0, 8)" src="#/product/225"></case-bar>
      </el-col>
    </el-row>

    <el-row class="footer__menu" :span="24" type="flex" justify="center">
      <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          router
          background-color="#393939"
          text-color="#fff"
          active-text-color="#ffd04b">
        <el-menu-item index="1" class="menu-line" route="/">网站首页</el-menu-item>
        <el-menu-item index="2" class="menu-line" route="/about">关于我们</el-menu-item>
        <el-submenu index="3">
          <template slot="title">作品案例</template>
          <el-menu-item index="3-1" route="/product/225">政府/企事业单位宣传片</el-menu-item>
          <el-menu-item index="3-2" route="/product/224">专题片/汇报片</el-menu-item>
          <el-menu-item index="3-3" route="/product/223">党建片</el-menu-item>
          <el-menu-item index="3-4" route="/product/209">产品广告片/公益广告片</el-menu-item>
          <el-menu-item index="3-5" route="/product/208">定制微电影</el-menu-item>
          <el-menu-item index="3-6" route="/product/207">MG动画</el-menu-item>
          <el-menu-item index="3-7" route="/product/206">音乐/MV</el-menu-item>
          <el-menu-item index="3-8" route="/product/205">晚会/演出/活动高清多机位录制</el-menu-item>
          <el-menu-item index="3-9" route="/product/204">网络直播</el-menu-item>
          <el-menu-item index="3-10" route="/product/203">比赛现场评分系统</el-menu-item>
        </el-submenu>
        <el-menu-item index="4" class="menu-line menu-line-left" route="/service">我们的服务</el-menu-item>
        <el-submenu index="5" route="/news">
          <template slot="title">影禾动态</template>
          <el-menu-item index="5-1" route="/news/1">公司动态</el-menu-item>
          <el-menu-item index="5-2" route="/news/2">行业资讯</el-menu-item>
          <el-menu-item index="5-3" route="/news/3">拍摄花絮</el-menu-item>
        </el-submenu>
        <el-menu-item index="6" class="menu-line-left" route="/contact">联系我们</el-menu-item>
      </el-menu>
    </el-row>
    <el-row type="flex" justify="center" class="description hidden-sm-and-down">
      <el-col :md="18" :lg="12">
        <el-descriptions title="影禾传媒    版权所有" extra="未经授权禁止复制" column="3" :colon="false">
          <el-descriptions-item label="联系电话：">18183520529</el-descriptions-item>
          <el-descriptions-item label="E-mail：">65990643@qq.com</el-descriptions-item>
          <el-descriptions-item label="联系人：">胡老师</el-descriptions-item>
          <el-descriptions-item label="联系地址：">云南省昆明市西山区新闻路348号秋园公园内影禾传媒</el-descriptions-item>
        </el-descriptions>
        <el-descriptions :colon="false" column="1">
          <el-descriptions-item label="备案号："><el-link href="https://beian.miit.gov.cn/" target="_blank">滇ICP备2021006068号</el-link></el-descriptions-item>
        </el-descriptions>
        <el-descriptions :colon="false" column="1">
          <el-descriptions-item label="技术支持："><el-link href="https://www.alpactech.com/" target="_blank">萌驼科技</el-link></el-descriptions-item>
        </el-descriptions>
      </el-col>
      <el-col :md="4" :lg="4">
        <el-row>
          <el-col>
            <el-image class="qrcode" :src="require('@/assets/images/20190524112137微信图片_20190524112104.jpg')"></el-image>
          </el-col>
          <el-col>
            <h4 class="label">【扫码关注】</h4>
          </el-col>
        </el-row>
      </el-col>
    </el-row>

    <el-row class="description hidden-md-and-up">
      <el-col :span="24">
        <el-descriptions title="影禾传媒    版权所有" extra="未经授权禁止复制" column="1" :colon="false">
          <el-descriptions-item label="联系电话：">18183520529</el-descriptions-item>
          <el-descriptions-item label="E-mail：">65990643@qq.com</el-descriptions-item>
          <el-descriptions-item label="联系人：">胡老师</el-descriptions-item>
          <el-descriptions-item label="联系地址：">云南省昆明市西山区新闻路348号秋园公园内影禾传媒</el-descriptions-item>
        </el-descriptions>
        <el-descriptions :colon="false" column="1">
          <el-descriptions-item label="备案号："><el-link href="https://beian.miit.gov.cn/" target="_blank">滇ICP备2021006068号</el-link></el-descriptions-item>
        </el-descriptions>
        <el-descriptions :colon="false" column="1">
          <el-descriptions-item label="技术支持："><el-link href="https://www.alpactech.com/" target="_blank">萌驼科技</el-link></el-descriptions-item>
        </el-descriptions>
      </el-col>
      <el-col :span="24">
        <el-row>
          <el-col>
            <el-image class="qrcode qrcode-sm" :src="require('@/assets/images/20190524112137微信图片_20190524112104.jpg')"></el-image>
          </el-col>
          <el-col>
            <h3 class="label">【使用微信识别二维码关注】</h3>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </el-col>
</template>

<script>
import CaseBar from "@/components/CaseBar";
import products from "@/mocks/products.json";
export default {
  name: "FooterBar",
  components: {CaseBar},
  props: [
    "activeIndex"
  ],
  data() {
    return {
      products: products,
      latest: []
    }
  },
  mounted() {
    Object.values(products).map(item => item.list).forEach(i => this.latest.push(...i))
  }
}
</script>

<style scoped>
.last {
  background-color: #F7F7F7;
  margin: 0 0;
}

.footer__menu {
  background: #393939;
  padding: 1px;
}

.description {
  margin: 20px 0;
}

.qrcode {
  padding: 8px 0 0;
  width: 8vw;
}

.qrcode-sm {
  width: 33vw;
}

.label {
  margin: 0;
}
</style>