<template>
  <el-divider class="title">
    <h1 class="divider__text">
      <slot name="white">{{white}}</slot><span class="text-red"><slot name="red">{{red}}</slot></span></h1>
    <h1 class="text-grey"><slot name="grey">{{grey}}</slot></h1>
    <p class="red-line"></p>
  </el-divider>
</template>

<script>
export default {
  name: "divider-line",
  props: [
      "white",
      "red",
      "grey"
  ]
}
</script>

<style scoped>

.text-red {
  color: #FF0000;
}

.text-grey {
  color: #b4b4b4;
}

.divider__text {
  color: #333333;
  font-size: 1.8rem;
  letter-spacing: -0.1rem;
  line-height: 10px;
}

.el-divider__text {
  /*background: #F7F7F7;*/
  background: url("../assets/bg-f7f7f7.png");
}

h4 {
  color: #666666;
}

.title p {
  width: 60px;
  height: 2px;
  background: #ff0000;
  margin: 10px auto 0;
}
</style>
