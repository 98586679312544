<template>
  <el-row type="flex" justify="center">
    <el-col :span="24">

      <el-row type="flex" justify="center">

        <el-col class="side hidden-sm-and-down" :md="6" :lg="6" type="flex" justify="center">
          <side-bar></side-bar>
        </el-col>

        <el-col :xs="18" :md="18">
          <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>我们的服务</el-breadcrumb-item>
          </el-breadcrumb>
          <el-row type="flex" justify="start">
            <el-col class="content">
              <div style="">
                <div>
                  <span style="font-size: 18px;"><span style="color: rgb(255, 0, 0);"><strong>云南影禾文化传媒有限公司</strong></span>是一家集<strong>文化创意策划</strong>、<strong>影视摄制</strong>、<strong>活动策划执行</strong>、<strong>新媒体集成</strong>等项目为一体的<strong>综合型文化传媒企业</strong>，汇聚了一批锐意进取的<strong>影视文化精英</strong>，拥有一流的<strong>专业技能</strong>和丰富的<strong>实战经验</strong>。业务范围包括各类<strong>宣传片</strong>、<strong>汇报片</strong>、<strong>纪录片</strong>、<strong>党建片</strong>、<strong>人物专题片</strong>、<strong>微电影创意摄制</strong>，<strong>三维动画</strong>、<strong>MG设计制作</strong>，<strong>活动</strong>或<strong>晚会</strong>八讯道高清录制和<strong>网络直播</strong>，<strong>晚会策划执行</strong>，<strong>比赛评分系统</strong>服务等。</span></div>
                <div>
                  &nbsp;</div>
                <div style="text-align: center;">
                  <span style="font-size: 18px;"><img alt="" src="../assets/logo.png" style="width: 40vw;" /></span></div>
                <div>
                  &nbsp;</div>
                <div>
                  <span style="font-size: 18px;"></span></div>
                <div>
                  <span style="font-size: 18px;"><span style="color: rgb(255, 0, 0);"><strong>云南影禾文化传媒公司</strong></span>成立于2016年4月，注册资金300万，具有云南省广电局核准的<strong>《广播电视节目制作经营许可证》</strong>，位于昆明西山区南博路<strong>万达CBD核心区</strong>。公司拥有卓越的品牌优势和充足的资金优势，拥有一支具有传统媒体从业经验的的专业团队，在同行业中具有显着的优势，自成立以来发展迅速，成效明显。公司始终围绕影视文化创意行业，从深入对接策划，到量身定制服务、全团队专业摄制、再到后期剪辑、包装设计、全媒体平台推广传播等，为客户提供全方位服务。</span></div>
                <div>
                  <span style="font-size: 18px;"></span></div>
                <div>
                  <span style="font-size: 18px;">公司团队实力雄厚，实战经验丰富，呈现新颖独特，通过整合资源与服务，以&ldquo;<strong>服务</strong>、<strong>专业</strong>、<strong>传播</strong>&rdquo;为定位，与<strong>国家税务总局云南省税务局</strong>、<strong>云南省环保厅</strong>、<strong>云南省水利厅</strong>、<strong>云南省林业厅</strong>、<strong>云南省国资委</strong>、<strong>曲靖经济技术开发区</strong>、<strong>楚雄经济技术开发区</strong>等各级政府机关，<strong>云南省话剧院</strong>、<strong>云南省花灯剧院</strong>、<strong>昆明市歌舞剧院</strong>、<strong>玉溪市花灯剧院</strong>等各类艺术院团，<strong>昆明理工大学</strong>、<strong>云南民族大学</strong>、<strong>云大附中</strong>、<strong>民大附中</strong>、<strong>建水一中</strong>、<strong>云师大附小</strong>等各类学校，<strong>建设银行</strong>、<strong>富滇银行</strong>、<strong>恒丰银行</strong>、<strong>云南省农村信用社</strong>、<strong>中国银联云南分公司</strong>等金融单位，<strong>华侨城</strong>、<strong>中国联航</strong>、<strong>中国电建集团</strong>、<strong>云铜集团</strong>、<strong>云南建投</strong>等各<strong>中央及省级国企</strong>，<strong>云南慈善总会</strong>、<strong>云南省注册会计师协会</strong>、<strong>云南省舞蹈家协会</strong>等社会组织等上百家单位建立了长期合作关系，具有前瞻性部署、专业化运作、规模化经营、跨界整合资源，得到客户及社会的广泛认同。</span></div>
                <div>
                  <span style="font-size: 18px;"></span></div>
                <div>
                  <span style="font-size: 18px;">公司承接摄制的作品在各类评奖中屡屡获奖。公益广告<strong>《宪法》</strong>荣获<strong>国家司法部微视频一等奖</strong>，电视散文<strong>《谁是最幸福的人》</strong>荣获全国消防系统微视频评比二等奖，纪录片<strong>《探秘朱苦拉》</strong>荣获国家广播电视协会三等奖，专题片<strong>《风情苍山雨》</strong>荣获云南省城市电视协会优秀电视节目制作二等奖，文艺节目<strong>《外国文艺之窗》</strong>荣获国家广播文艺电台节目二等奖，MV<strong>《山若有家》</strong>荣获全国总工会第二届&ldquo;劳动最美&rdquo;微视频大赛最佳MV制作奖，微电影<strong>《笔之谜》</strong>、<strong>《大朝山来信》</strong>荣获云南省总工会第三届&ldquo;劳动最美&rdquo;微视频大赛三等奖。</span></div>
                <div>
                  <span style="font-size: 18px;"></span></div>
                <div>
                  <span style="font-size: 18px;">专业人做专业事！我们生产的不是产品，而是作品。公司将秉承专业的态度、专业的服务、专业的水平继续为新老朋友提供更加<strong>优质的服务</strong>。</span></div>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
import SideBar from "@/components/SideBar";
export default {
  name: "service",
  components: {SideBar},
}
</script>

<style scoped>
.side {
  margin: 64px 24px;
}

.breadcrumb {
  padding: 24px;
}

.content {
  padding: 24px;
  margin-bottom: 128px;
  text-align: justify;
  text-indent: 2.3rem;
  color: rgb(63, 63, 63);
  font-family: Verdana, Arial, Helvetica, sans-serif;
}
</style>