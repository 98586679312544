<template>
  <el-row type="flex" justify="center">
    <el-col :span="24">

      <el-row type="flex" justify="center">

        <el-col class="side hidden-sm-and-down" :md="6" :lg="6" type="flex" justify="center">
          <side-bar></side-bar>
        </el-col>

        <el-col :xs="20" :md="18" :lg="18">
          <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>{{ products[this.$route.name].title }}</el-breadcrumb-item>
          </el-breadcrumb>
          <el-row type="flex" justify="start">
            <el-col class="content">
              <product-bar title="政府/企事业单位宣传片" :products="products[this.$route.name].list"></product-bar>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
import products from '@/mocks/products.json'
import SideBar from "@/components/SideBar";
import ProductBar from "@/components/ProductBar";
export default {
  name: "product",
  components: {ProductBar, SideBar},
  data() {
    return {
      products: products
    }
  }
}
</script>

<style scoped>
.side {
  margin: 64px 24px;
}

.breadcrumb {
  padding: 24px;
}

.content {
  margin-bottom: 128px;
  color: rgb(63, 63, 63);
  font-family: Verdana, Arial, Helvetica, sans-serif;
}
</style>