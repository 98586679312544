<template>
  <el-row type="flex" justify="center">
    <el-col :span="24">

      <el-row type="flex" justify="center">

        <el-col class="side hidden-sm-and-down" :md="6" :lg="6" type="flex" justify="center">
          <side-bar></side-bar>
        </el-col>

        <el-col :xs="18" :md="18">
          <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>联系我们</el-breadcrumb-item>
          </el-breadcrumb>
          <el-row type="flex" justify="start">
            <el-col class="content">
              <div style="color: rgb(63, 63, 63); font-family: Verdana, Arial, Helvetica, sans-serif;">
                <span style="font-size: 18px;"><span style="color: rgb(255, 0, 0);"><strong>云南影禾文化传媒有限公司</strong></span>是一家集<strong>文化创意策划</strong>、<strong>影视摄制</strong>、<strong>活动策划执行</strong>、<strong>新媒体集成</strong>等项目为一体的<strong>综合型文化传媒企业</strong>，汇聚了一批锐意进取的<strong>影视文化精英</strong>，拥有一流的<strong>专业技能</strong>和丰富的<strong>实战经验</strong>。业务范围包括各类<strong>宣传片</strong>、<strong>汇报片</strong>、<strong>纪录片</strong>、<strong>党建片</strong>、<strong>人物专题片</strong>、<strong>微电影创意摄制</strong>，<strong>三维动画</strong>、<strong>MG设计制作</strong>，<strong>活动</strong>或<strong>晚会</strong>八讯道高清录制和<strong>网络直播</strong>，<strong>晚会策划执行</strong>，<strong>比赛评分系统</strong>服务等。</span></div>
              <div style="color: rgb(63, 63, 63); font-family: Verdana, Arial, Helvetica, sans-serif;">
                &nbsp;</div>
              <div style="color: rgb(63, 63, 63); font-family: Verdana, Arial, Helvetica, sans-serif; text-align: center;">
                <span style="font-size: 18px;"><img alt="" src="../assets/logo.png" style="width: 40vw;" /></span></div>
              <div style="color: rgb(63, 63, 63); font-family: Verdana, Arial, Helvetica, sans-serif;">
                &nbsp;</div>
              <div style="color: rgb(63, 63, 63); font-family: Verdana, Arial, Helvetica, sans-serif;">
                <span style="font-size: 18px;"></span></div>
              <div>
                &nbsp;</div>
              <div>
                <span style="font-size:18px;">公司地址：云南省昆明市西山区新闻路348号秋园公园内影禾传媒</span></div>
              <div>
                <span style="font-size:18px;">联系电话：18183520529</span></div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
import SideBar from "@/components/SideBar";

export default {
  name: "contact",
  components: {SideBar},
}
</script>

<style scoped>
.side {
  margin: 64px 24px;
}

.breadcrumb {
  padding: 24px;
}

.content {
  padding: 24px;
  margin-bottom: 128px;
  text-align: justify;
  text-indent: 2.3rem;
  color: rgb(63, 63, 63);
  font-family: Verdana, Arial, Helvetica, sans-serif;
}
</style>