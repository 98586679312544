<template>
  <el-row class="latest">
    <el-carousel class="latest__carousel" :interval="4000" type="card" height="24vw" indicator-position="none">
      <el-carousel-item class="latest__item" v-for="(item, key) in latest.slice(0, 10)" :key="key">
        <a class="latest__link" :href="item.src" target="_blank">
          <el-image class="latest__img" :src="require('@/assets/images/' + item.img)" fit="fill"></el-image>
          <h4 class="latest__title" style="margin-top: 0;">{{ item.title }}</h4>
        </a>
      </el-carousel-item>
    </el-carousel>
    <el-carousel class="latest__carousel" :interval="4000" type="card" height="24vw" indicator-position="none">
      <el-carousel-item class="latest__item" v-for="(item, key) in latest.slice(10, 20)" :key="key">
        <a class="latest__link" :href="item.src" target="_blank">
          <el-image class="latest__img" :src="require('@/assets/images/' + item.img)" fit="fill"></el-image>
<!--          <el-image style="width: 21vw; height: 12vw" :src="require('@/assets/images/' + item.img)" fit="fill"></el-image>-->
          <h4 class="latest__title" style="margin-top: 0;">{{ item.title }}</h4>
        </a>
      </el-carousel-item>
    </el-carousel>
  </el-row>
</template>

<script>
import products from "@/mocks/products.json";

export default {
  name: "latest-bar",
  data() {
    return {
      products: products,
      latest: []
    }
  },
  mounted() {
    Object.values(products).map(item => item.list).forEach(i => this.latest.push(...i))
  }
}
</script>

<style scoped>
.latest {
  background-color: #F7F7F7;
  margin: 80px 0 0;
}

.latest__carousel {
  margin: 0 0 8px;
  padding: 0;
}

.latest__link {
  color: #2c3e50;
  text-decoration: none;
}

.latest__img {
  width: 64%;
  aspect-ratio: 16 / 9;
}

.latest__title {
  font-size: 1.2em;
}

@media (max-width: 768px) {
  .latest__carousel {
    margin: 0 0 4px;
    padding: 0;
  }

  .latest__title {
    font-size: 0.8em;
    word-break: break-all;
    display: none;
  }

}
</style>
